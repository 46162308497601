import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Link, NavLink, Outlet, useOutletContext } from "react-router-dom";
import React from "react";
import SpinnerCentered from "common/SpinnerCentered";
import InfoCard from "common/InfoCard";

export default function Dashboard() {
  const { draftDoc } = useOutletContext();

  function renderCard(busDoc) {
    let content;

    if (busDoc.data) {
      const docData = busDoc.data;
      const isOnline = !!docData.urlId;
      const waitCreate = !isOnline && docData.draft_status.id === "waiting";
      const waitUpdate = isOnline && docData.draft_status.id === "waiting";
      content = (
        <>
          <h4>{docData.title}</h4>
          <p className="mb-2">
            {isOnline && (
              <Badge bg="success" id="d_page_link" className="me-2">
                Online
              </Badge>
            )}
            {waitCreate && (
              <Badge bg="danger" className="me-2">
                In attesa di approvazione
              </Badge>
            )}
            {waitUpdate && (
              <Badge bg="warning" text="dark" className="me-2">
                Modifiche in attesta di approvazione
              </Badge>
            )}
          </p>

          {docData.urlId && (
            <p>
              <Link to={`/${docData.urlId}`} rel="noopener noreferrer">
                {`www.joppys.pet/${docData.urlId}`}
              </Link>
            </p>
          )}
          {isOnline && (
            <p>
              <i className="bi bi-check-lg me-2"></i>La tua pagina è visibile
              sulla mappa di Joppys e nei risultati di ricerca.
            </p>
          )}
          <p>
            <i className="bi bi-info-circle me-2"></i>Nelle prossime ore lo
            staff di Joppys verificherà le informazioni che ci hai fornito.
          </p>
          <Button to="/business/app/edit_business/info" as={Link}>
            <i className="bi bi-pen me-2"></i>Modifica
          </Button>
        </>
      );
    } else {
      content = (
        <>
        <h4>Benvenuta/o in Joppys!</h4>
          <p>
          Crea in pochi passi la pagina gratuita per la tua attività
          </p>

          <Button to="/business/app/add_business" as={Link}>
            Crea pagina<i className="bi bi-arrow-right ms-2"></i>
          </Button>
        </>
      );
    }

    return (
      // <Card>
      //   <Card.Header as="h5">La tua pagina</Card.Header>
      //   <Card.Body>
      <>{content}</>
      // </Card.Body>
      //  </Card>
    );
  }

  return !draftDoc ? (
    <SpinnerCentered />
  ) : (
    <>
      <Row>
        <Col xs="auto"> 
          {renderCard(draftDoc)}
          <Alert variant="warning" className="mt-3">
          Joppys Business è in versione beta. Contattaci per qualsiasi necessità.
          </Alert>
        </Col>
      </Row>
    </>
  );
}
