import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "scripts/FirebaseApp";

import { useEffect, useState } from "react";

const businessDraftPath = "business_draft";
const userPath = "users";

export class CodeError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }

  static notFound = "not-found";

  isNotFound() {
    return this.code === CodeError.notFound;
  }
}

// export async function subscribeNewsletter(docData) {
//   // email as document id
//   docData.date = serverTimestamp()
//   const newDocRef = doc(db, "public_forms", "newsletter", "app_launch", docData.email)
//   return setDoc(newDocRef, docData)
// }

// create nested object from flat object with dot notation
// docRaw is the flat object to convert
// tag is the tag to remove from the flat object, it used for conditional parameters of the form not to be stored
export function convertDotNotation(docRaw, tag) {
  const separator = ".";
  const asArray = Object.entries(docRaw);
  // filter out tag
  const filtered = asArray.filter(
    ([key, value]) => !key.startsWith(tag + separator)
  );

  docRaw = Object.fromEntries(filtered);

  let doc = {};
  for (const keyRaw in docRaw) {
    const myArray = keyRaw.split(separator);

    let obj = doc;
    let len = myArray.length;
    for (let i = 0; i < len - 1; i++) {
      if (myArray[i].endsWith("[]")) {
        myArray[i] = myArray[i].substring(0, myArray[i].length - 2);
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = [];
        }
      } else {
        if (!obj[myArray[i]]) {
          obj[myArray[i]] = {};
        }
      }
      obj = obj[myArray[i]];
    }
    obj[myArray[len - 1]] = docRaw[keyRaw];
  }
  console.log(doc);
  return doc;
}

export async function updateUserDoc(uid, userDoc) {
  console.log("updating users/" + uid);

  const docRef = doc(db, userPath, uid);
  return updateDoc(docRef, userDoc, { merge: true });
}

export async function createBusinessDraft(businessDraftDoc, uid) {
  console.log(businessDraftDoc);
  const docData = convertDotNotation(businessDraftDoc, "form");

  // phone.primary and phone.secondary are converted to an unique array phone
  docData.phone.primary.main = true;

  let phoneArray = [docData.phone.primary];
  if (docData.phone.secondary?.num?.length > 0) {
    phoneArray.push(docData.phone.secondary);
  }

  delete docData.phone.primary;
  delete docData.phone.secondary;
  docData.phones = phoneArray;

  docData.address.geo.hash = "";

  docData.ownerId = uid;
  docData.draft_status = {
    id: "waiting",
    date_create: serverTimestamp(),
  };
  console.log(docData);

  const newDocRef = collection(db, businessDraftPath);
  await addDoc(newDocRef, docData);
}

// single
export async function getBusinessDraft(uid) {
  const c = collection(db, businessDraftPath);
  const q = query(c, where("ownerId", "==", uid));
  const qs = await getDocs(q);

  if (qs.empty)
    throw new CodeError(
      `Documento con ownerId "${uid}" non esiste`,
      CodeError.notFound
    );
  return {
    id: qs.docs[0].id,
    data: qs.docs[0].data(),
  };
}

// when useBusinessDraftDoc null, loading
// when useBusinessDraftDoc {}, not-found or other errors
// when useBusinessDraftDoc .data, document exist and ready
export function useBusinessDraftDocSingle(uid) {
  const [draftDoc, setDraftDoc] = useState();

  useEffect(() => {
    getBusinessDraft(uid)
      .then((fbDoc) => setDraftDoc(fbDoc))
      .catch((e) => {
        if (e instanceof CodeError && e.isNotFound()) {
        }
        setDraftDoc({});
      });
  }, [uid]);

  return draftDoc;
}

export function useBusinessDraftDoc(uid) {
  const [draftDoc, setDraftDoc] = useState();

  useEffect(() => {
    const c = collection(db, businessDraftPath);
    const q = query(c, where("ownerId", "==", uid));
    const unsub = getSnapshot(q, (doc) => setDraftDoc(doc));
    return () => unsub();
  }, [uid]);

  return draftDoc;
}

// generic function for retrieve document from firebase and handle dasta
// when null, loading
// when {}, not-found or other errors
// when .data, document exist and ready
// when false, error
export function getDocumentSnapshot(q, callback) {

  return onSnapshot(
    q,
    (qs) => {
      console.log("getDocumentSnapshot: onSnapshot");
      if (qs.empty) {
        callback({});
      } else {
        callback({
          id: qs.id,
          data: qs.data(),
        });
      }
    },
    (error) => {
      console.log(error);
      callback(false);
    }
  );
}

// generic function for retrieve collection from firebase and handle dasta
// when null, loading
// when {}, not-found or other errors
// when .data, document exist and ready
// when false, error
export function getSnapshot(q, callback) {
  return onSnapshot(
    q,
    (qs) => {
      console.log("getSnaposhot: onSnapshot");
      if (qs.empty) {
        callback({});
      } else {
        callback({
          id: qs.docs[0].id,
          data: qs.docs[0].data(),
        });
      }
    },
    (error) => {
      console.log(error);
      callback(false);
    }
  );
}

export function useUserDoc(uid) {
  const [userDoc, setUserDoc] = useState();

  console.log("useUserDoc");
  useEffect(() => {
    const dr = doc(db, userPath, uid);
    const q = query(dr);
    const unsub = getDocumentSnapshot(q, (doc) => setUserDoc(doc));
    return unsub;
  }, [uid]);

  return userDoc;
}

export function cleanFromTag(docRaw, tag) {
  const separator = ".";
  const asArray = Object.entries(docRaw);
  // filter out tag
  const filtered = asArray.filter(
    ([key, value]) => !key.startsWith(tag + separator)
  );

  docRaw = Object.fromEntries(filtered);
  return docRaw;
}

export async function updateBusinessDraft(docId, businessDraft) {
  console.log("updating doc: " + docId);
  console.log(businessDraft);
  const docData = cleanFromTag(businessDraft, "NO_DB");
  console.log(docData);

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
  docData["draft_status.id"] = "waiting";
  docData["draft_status.date_edit"] = serverTimestamp();
  console.log(docData);
  const docRef = doc(db, businessDraftPath, docId);
  return updateDoc(docRef, docData, { merge: true });
}

