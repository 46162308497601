import {
  Button,
  Col,
  Collapse,
  Container,
  Modal,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useAuth } from "context/AuthContext";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";



import JoppysImage, { iLogoBusiness } from "img/JoppysImage";
import { useAppBusinessContext } from "context/AppBusinessContext";

function AppBusiness(props) {
  const { currentUser, logout } = useAuth();
  const { draftDoc } = useAppBusinessContext();

  


  const [showSidebar, setShowSidebar] = useState(false);
  const [busMenuOpen, setBusMenuOpen] = useState(false);

  const { pathname } = useLocation();

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  async function handleLogout() {
    try {
      await logout();
      // navigate("")
    } catch (e) {
      console.log(e);
    }
  }

  
  useEffect(() => {
    // opens sidebar menu depending on selected page
    console.log("pathname useEffect: " + pathname)
    if (pathname.startsWith("/business/app/edit_business")) {
      setBusMenuOpen(true)
    } 
  }, [pathname])


    return (
      <>
        <Navbar bg="white" expand="md" className="border-bottom">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/business/app">
                <JoppysImage
                  style={{ height: "50px" }}
                  content={iLogoBusiness}
                />
              </Link>
            </Navbar.Brand>
            <Navbar>
              <Button
                className="d-md-none border"
                variant="light"
                onClick={handleShowSidebar}
              >
                <span className="navbar-toggler-icon"></span>
              </Button>
            </Navbar>
          </Container>
        </Navbar>

        <Container fluid>
          <Row>
            {/* SIDEBAR */}
            <Col md={3} lg={2} className="p-0" id="app-sidebar">
              <Offcanvas
                show={showSidebar}
                onHide={handleCloseSidebar}
                responsive="md"
                // style={{ minHeight: "calc(100vh - 77px)" }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Dashboard</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-md-3 flex-column">
                  <Nav className="flex-column" variant="pills">
                    <NavLink className="nav-link" to="dashboard">
                      <i className="bi bi-pie-chart me-2"></i>Dashboard
                    </NavLink>
                    <Nav.Link
                      className="nav-link btn-toggle"
                      onClick={() => setBusMenuOpen(!busMenuOpen)}
                      aria-controls="busMenu"
                      aria-expanded={busMenuOpen}
                    >
                      <i className="bi bi-briefcase me-2"></i>Attività
                    </Nav.Link>
                    <Collapse in={busMenuOpen} id="busMenu">
                      <div className="ms-4">
                        <div>
                          <NavLink className="nav-link" to="edit_business/info">
                            Generale
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="edit_business/address"
                          >
                            Indirizzo e mappa
                          </NavLink>
                        </div>
                      </div>
                    </Collapse>
                    <hr className="mx-0 my-1" />
                    <NavLink
                      className="nav-link"
                      id={"settings"}
                      to={"settings"}
                    >
                      <i className="bi bi-gear me-2"></i>Impostazioni
                    </NavLink>
                    <Nav.Link className="nav-link" onClick={handleLogout}>
                      <i className="bi bi-box-arrow-right me-2"></i>Esci
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
            {/* BODY */}
            <Col className="p-4">
              <Outlet context={{ currentUser,  draftDoc }} />
            </Col>
          </Row>
        </Container>
        <Modal show={false}
        //  onHide={handleModalClose} 
         centered>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Attenzione!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Non hai salvato le modifiche. Vuoi procedere senza salvare?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary"
            //  onClick={handleModalClose}
             >
              Torna alle modifiche
            </Button>
            <Button variant="primary" 
            // onClick={handleModalNext}
            >
              Ignora le modifiche
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );

}

export default AppBusiness;
