import React from "react";
import { useOutletContext } from "react-router-dom";

export default function Settings() {
  const { currentUser } = useOutletContext();
  return (
    <>
      <h4>Impostazioni</h4>
      <p>
        email: {currentUser.email}
        <br />
        uid: {currentUser.uid}
      </p>
    </>
  );
}
