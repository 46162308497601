import { useAuth } from "context/AuthContext";
import React, { useState, useContext, useEffect, createContext } from "react";
import { updateUserDoc, useBusinessDraftDoc, useUserDoc } from "scripts/private";
import SpinnerCentered from "common/SpinnerCentered";

import { Timestamp, serverTimestamp } from "firebase/firestore";

const AppBusinessContext = createContext();

export function useAppBusinessContext() {
  return useContext(AppBusinessContext);
}

// in questo componente faccio tutti i caricamenti necessari per i valori comuni

export function AppBusinessContextProvider({ children }) {
  const { currentUser, logout } = useAuth();
  const draftDoc = useBusinessDraftDoc(currentUser.uid);
  const userDoc = useUserDoc(currentUser.uid);

  const [variable1, setVariable1] = useState("Hello, World!");
  const [variable2, setVariable2] = useState("React is great!");

  useEffect(() => {
    // check if the user is new and set as business account
    // also set latestAccessWeb if latest is before 5 minutes ago
    if (userDoc?.data) {
      let userDocMerge = {};
      console.log("check user");
      // if not already business and isNewUser set isBusiness
      let isNewUser =
        currentUser.metadata.lastLoginAt === currentUser.metadata.createdAt;
      if (!userDoc.data.isBusiness && isNewUser) {
        // isNewUser
        userDocMerge.isBusiness = true;
      }
      if (userDoc.data.latestAccessWeb) {
        let nowMillis = Timestamp.now().toMillis();
        let latestAndOffset =
          userDoc.data.latestAccessWeb.toMillis() + 5 * 60 * 1000; // latestAccess + 5 minutes
        if (latestAndOffset < nowMillis) {
          userDocMerge.latestAccessWeb = serverTimestamp();
        }
      }
      if (Object.keys(userDocMerge).length !== 0) {
        updateUserDoc(currentUser.uid, userDocMerge);
      }
    }
  }, [userDoc, currentUser]);
  
  const value = {
    userDoc,
    draftDoc,
    variable1,
    setVariable1,
    variable2,
    setVariable2,
  };

  if (!draftDoc | !userDoc) {
    return (
      <>
        <div className="d-flex justify-content-center min-height-100vh">
          <SpinnerCentered />
        </div>
      </>
    );
  } else {
    return (
      <AppBusinessContext.Provider value={value}>
        {children}
      </AppBusinessContext.Provider>
    );
  }
  // return <AppBusinessContext.Provider value={value}>{!loading && children}</AppBusinessContext.Provider>

  // // useEffect(() => {

  // //   const unsubscribe = firebaseCheckUser((user) => {
  // //     setCurrentUser(user)
  // //     setLoading(false)
  // //   })

  // //   return unsubscribe
  // // }, [])

  // const value = { variable1, setVariable1, variable2, setVariable2 }
  // return <AppBusinessContext.Provider value={value}>{!loading && children}</AppBusinessContext.Provider>
}
