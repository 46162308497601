import React from "react"
import {
  businessContactsFields as fields,
  businessFieldNames,
  FormState,
  InputGroupBox,
  setField,
} from "routes/business/app/BusinessForm"

export default function EditBusContactsForm({ formStatus, errors, form, setForm }) {
  return (
    <>
      <div className="vstack gap-2">
        <InputGroupBox
          disabled={formStatus !== FormState.editing}
          value={form[businessFieldNames.phone_primary]}
          id={businessFieldNames.phone_primary}
          errors={errors}
          fields={fields}
          onChange={(e) => setField(e.target.id, e.target.value, setForm)}
        />
      </div>
    </>
  )
}
