import React, { useState, useEffect, useRef } from "react"
import { Form, Button, Alert, Card, Container, Col, Row, FloatingLabel } from "react-bootstrap"

import { useAuth } from "context/AuthContext"
import { Link } from "react-router-dom"
import Padding from "common/Padding"

export default function RecoveryPassword() {
  const [completed, setCompleted] = useState(false)

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const { resetPassword } = useAuth()

  async function handleSubmit(e) {
    e.preventDefault()

    setError("")
    try {
      setLoading(true)
      await resetPassword(email)
      setCompleted(true)
    } catch (e) {
      setError(e.code)
    }

    setLoading(false)
  }

  return (
    <>
    <Padding>
      <Container>
        <Row className="justify-content-center">
          <Col md={7} lg={6}>
            <Card className="p-4">
              {completed ? (
                <Card.Body>
                  <div className="mb-4">
                    <h3 className="mb-4">Controlla la tua e-mail</h3>
                  </div>

                  <div className="mt-2">
                    Ti abbiamo inviato una mail a <b>{email}</b> con le istruzioni per reimpostare la password.
                    <br /> Il messaggio potrebbe arrivare tra qualche minuto.
                  </div>
                  <div className="mt-4">
                    <Link to="/business/login">Torna alla pagina di Login</Link>
                  </div>
                </Card.Body>
              ) : (
                <Card.Body>
                  <div className="mb-4">
                    <h3 className="mb-4">Hai dimenticato la password?</h3>
                    <div>
                      Inserisci la tua email. Riceverai un messaggio con un link per impostare la tua nuova password.
                    </div>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                      <FloatingLabel controlId="email" label="Email" className="mb-2">
                        <Form.Control
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="Email"
                        />
                      </FloatingLabel>
                    </Form.Group>
                    {error && (
                      <Alert className="mb-2" variant="danger">
                        {error}
                      </Alert>
                    )}
                    <Button disabled={loading} variant="primary" className="w-100 mb-2" size="lg" type="submit">
                      Reimposta Password
                    </Button>

                    <div className="mt-2 text-center">
                      <Link preventScrollReset={true} to="/business/login">
                        Login
                      </Link>
                    </div>
                  </Form>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      </Padding>
    </>
  )
}
