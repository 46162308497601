import React from "react"
import {
  FormState,
  InputGroupBox,
  setField,
  businessOwnerInfoFields as fields,
  businessFieldNames,
} from "routes/business/app/BusinessForm"

export default function EditBusOwnerInfoForm({ formStatus, errors, form, setForm }) {
  return (
    <>
      <div className="vstack gap-2">
        <InputGroupBox
          disabled={formStatus !== FormState.editing}
          value={form[businessFieldNames.owner_name]}
          id={businessFieldNames.owner_name}
          errors={errors}
          fields={fields}
          onChange={(e) => setField(e.target.id, e.target.value, setForm)}
        />
        <InputGroupBox
          disabled={formStatus !== FormState.editing}
          value={form[businessFieldNames.owner_surname]}
          id={businessFieldNames.owner_surname}
          errors={errors}
          fields={fields}
          onChange={(e) => setField(e.target.id, e.target.value, setForm)}
        />
        <InputGroupBox
          disabled={formStatus !== FormState.editing}
          value={form[businessFieldNames.owner_phone]}
          id={businessFieldNames.owner_phone}
          errors={errors}
          fields={fields}
          onChange={(e) => setField(e.target.id, e.target.value, setForm)}
        />
      </div>
    </>
  )
}
