import React from "react";

import Home from "routes/Home";

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Root from "routes/Root";
import ErrorPage from "routes/ErrorPage";
import AppBusiness from "routes/business/app/AppBusiness";
import About from "routes/About";
// import BusinessHome from "routes/BusinessHome"

import PrivateAuthRoute from "components/PrivateAuthRoute";

import BusinessPublic from "BusinessPublic";
import BusinessRoot from "routes/business/BusinessRoot";
import BusinessHome from "routes/business/BusinessHome";
import RecoveryPassword from "components/RecoveryPassword";

import Contacts from "routes/Contacts";
import Privacy from "routes/legal/Privacy";
import NewsletterApp from "routes/newsletter/NewsletterApp";
import TitleWrapper from "TitleWrapper";
import SignUp from "routes/business/SignUp";
import LogIn from "routes/business/LogIn";
import BusinessCard from "routes/card/BusinessCard";

import { createFlyerLog, getBusinessPublic } from "scripts/public";

// import MapNew3 from "common/MapNew3"
import AddBusiness from "routes/business/app/AddBusiness";
import EditBusiness from "routes/business/app/EditBusiness";
import EditWrapper from "routes/business/app/edit/EditWrapper";
import Stripe from "routes/business/Stripe";
import AppRecoveryPassword from "components/AppRecoveryPassword";
import { AuthContextProvider } from "context/AuthContext";
import Header from "common/Header";
import Dashboard from "routes/business/app/Dashboard";
import Settings from "routes/business/app/Settings";
import { AppBusinessContextProvider } from "context/AppBusinessContext";

//https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
const routes = [
  {
    path: "/",
    element: (
      <>
        <ScrollRestoration />
        <TitleWrapper>
          <Outlet />
        </TitleWrapper>
      </>
    ),
    children: [
      {
        path: "",
        element: (
          <>
            <Root />
          </>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: ":businessId",
            element: <BusinessPublic />,
            loader: async ({ params }) => {
              try {
                return await getBusinessPublic(params.businessId);
              } catch (e) {
                return false;
              }
            },
          },
          {
            path: "card",
            element: <Home />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "contacts",
            element: <Contacts />,
          },
          {
            path: "legal/privacy",
            element: <Privacy />,
          },
          {
            path: "app/recovery-password",
            element: (
              <AuthContextProvider>
                <AppRecoveryPassword />
              </AuthContextProvider>
            ),
          },
          // {
          //   path: "newsletter/app",
          //   element: <NewsletterApp />,
          // },
        ],
      },
      {
        path: "business",
        element: (
          <>
            <AuthContextProvider>
              <Outlet />
            </AuthContextProvider>
          </>
        ),
        children: [
          {
            path: "",
            element: (
              <>
                <BusinessRoot>
                  <Outlet />
                </BusinessRoot>
              </>
            ),
            children: [
              {
                path: "",
                element: <BusinessHome />,
              },
              {
                path: "newsletter",
                element: <NewsletterApp />,
              },
              {
                path: "signup",
                element: <SignUp />,
              },
              {
                path: "login",
                element: <LogIn redirectToDashboadOnLogin={true} />,
              },
              {
                path: "recovery-password",
                element: <RecoveryPassword />,
              },
            ],
          },
          {
            path: "app",
            element: (
              <PrivateAuthRoute>
                <Outlet />
              </PrivateAuthRoute>
            ),
            children: [
              {
                path: "add_business",
                element: <AddBusiness />,
              },
              {
                path: "",
                element: (
                  <>
                    <AppBusinessContextProvider>
                      <AppBusiness/>
                    </AppBusinessContextProvider>
                  </>
                ),
                children: [
                  {
                    path: "",
                    element: <Navigate to="dashboard" />,
                  },
                  {
                    path: "dashboard",
                    element: <Dashboard />,
                  },
                  {
                    path: "settings",
                    element: <Settings />,
                  },
                  {
                    path: "edit_business",
                    element: <EditBusiness />,
                    children: [
                      {
                        path: "info",
                        element: <EditWrapper section="info" />,
                      },
                      {
                        path: "contacts",
                        element: <EditWrapper section="contacts" />,
                      },
                      {
                        path: "address",
                        element: <EditWrapper section="address" />,
                      },
                      {
                        path: "owner_info",
                        element: <EditWrapper section="owner_info" />,
                      },
                      // {
                      //   path: "owner_info",
                      //   element: <EditBusOwnerInfo />,
                      // },
                      // {
                      //   path: "pro",
                      //   element: <EditWrapper section="info" />,
                      // },
                    ],
                  },
                  {
                    path: "stripe",
                    element: <Stripe />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        // flyers marcia delle ville
        path: "adv",
        element: <Navigate to={"/"} />,
        loader: async () => {
          try {
            console.log("provo a scrivere");
            return await createFlyerLog("aprile2024");
          } catch (e) {
            console.log(e);
            return false;
          }
        },
      },
      {
        path: "card/diego",
        element: <BusinessCard />,
      },
      {
        path: "inc/header-blog",
        element: <Header blog={true} />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
