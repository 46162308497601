import { Status } from "@googlemaps/react-wrapper"
import GoogleMapsApiWrapper, { geocodeAddress, Marker, MyMapComponent } from "common/GoogleMapsApi"
import JoppysImage, { iMapPin } from "img/JoppysImage"
import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import {
  FormState,
  InputGroupBox,
  setField,
  businessAddressFields as fields,
  businessFieldNames,
  scrollToError,
  checkNoErrors,
  getSubSet,
} from "routes/business/app/BusinessForm"

export default function EditBusAddressForm({ formStatus, errors, form, setForm }) {
  console.log(form)
  const [addressChanged, setAddressChanged] = useState(false)
  const [markerCalculated, setMarkerCalculated] = useState(true)

  const [geocoder, setGeocoder] = useState()

  useEffect(() => {
    if (addressChanged) {
      setField(businessFieldNames.form_address_check, false, setForm)
    }
  }, [addressChanged, setForm])

  return (
    <>
      <div className="vstack gap-2">
        <Row>
          <InputGroupBox
            as={Col}
            xs={8}
            disabled={formStatus !== FormState.editing}
            value={form[businessFieldNames.address_street]}
            id={businessFieldNames.address_street}
            errors={errors}
            fields={fields}
            onChange={(e) => {
              setAddressChanged(true)
              setField(e.target.id, e.target.value, setForm)
            }}
          />
          <InputGroupBox
            as={Col}
            xs={4}
            disabled={formStatus !== FormState.editing}
            value={form[businessFieldNames.address_house_number]}
            id={businessFieldNames.address_house_number}
            errors={errors}
            fields={fields}
            onChange={(e) => {
              setAddressChanged(true)
              setField(e.target.id, e.target.value, setForm)
            }}
          />
        </Row>
        <Row>
          <InputGroupBox
            disabled={formStatus !== FormState.editing}
            value={form[businessFieldNames.address_city]}
            id={businessFieldNames.address_city}
            errors={errors}
            fields={fields}
            onChange={(e) => {
              setAddressChanged(true)
              setField(e.target.id, e.target.value, setForm)
            }}
          />
        </Row>
        <Row>
          <InputGroupBox
            as={Col}
            xs={7}
            disabled={formStatus !== FormState.editing}
            value={form[businessFieldNames.address_district]}
            id={businessFieldNames.address_district}
            errors={errors}
            fields={fields}
            onChange={(e) => {
              setAddressChanged(true)
              setField(e.target.id, e.target.value, setForm)
            }}
          />
          <InputGroupBox
            as={Col}
            xs={5}
            disabled={formStatus !== FormState.editing}
            value={form[businessFieldNames.address_postal_code]}
            id={businessFieldNames.address_postal_code}
            errors={errors}
            fields={fields}
            onChange={(e) => {
              setAddressChanged(true)
              setField(e.target.id, e.target.value, setForm)
            }}
          />
        </Row>
      </div>
      {formStatus === FormState.editing && (
        <Col className="mt-5">
          <div className="text-larger mb-2">Controlla la posizione sulla mappa!</div>
          <div>
            Per farti trovare è importante che la posizione della tua attività sia corretta. Posizioneremo il cursore in
            base all'indirizzo che ci hai fornito. Se la posizione non è esatta, o vuoi indicarla con più precisione,
            trascina il segnalino
            <JoppysImage content={iMapPin} className="mx-2" />
            {/* <img width="20" className="mx-2" alt="" src="google_maps_pin.svg" /> */}
            sulla mappa.
          </div>
        </Col>
      )}

      <Row className="mt-3">
        <Col className="mx-auto">
          <GoogleMapsApiWrapper
            callback={(status, loader) => {
              if (status === Status.SUCCESS) {
                loader.load().then((google) => {
                  !geocoder && setGeocoder(new google.maps.Geocoder())
                })
              }
            }}
          >
            <div className="position-relative">
              <MyMapComponent
                className="rounded-3"
                height={400}
                zoom={17}
                center={form[businessFieldNames.geo_location]}
              >
                {form[businessFieldNames.geo_location] && (
                  <Marker
                    draggable={formStatus === FormState.editing}
                    position={form[businessFieldNames.geo_location]}
                    onDragEnd={(e) => {
                      setField([businessFieldNames.geo_location], e.latLng.toJSON(), setForm)
                    }}
                  />
                )}
              </MyMapComponent>

              {(addressChanged || !markerCalculated) && (
                <div>
                  <div className="position-absolute w-100 h-100 top-50 start-50 translate-middle bg-dark opacity-75"></div>
                  <div className="position-absolute top-50 start-50 translate-middle bg-light rounded-4 p-4 text-center">
                    <p>
                      {" "}
                      {markerCalculated ? (
                        <>
                          L'indirizzo è stato modificato. <br />
                          Ricalcola la posizione del cursore.
                        </>
                      ) : (
                        <>Sulla base dell'indirizzo fornito verrà posizionato il segnalino sulla mappa</>
                      )}
                    </p>
                    <Button
                      onClick={(e) => {
                        console.log(getSubSet(errors, "address"))
                        if (checkNoErrors(getSubSet(errors, "address"))) {
                          const fullAddress = `${form[businessFieldNames.address_street] ?? ""}, ${
                            form[businessFieldNames.address_house_number] ?? ""
                          }, ${form[businessFieldNames.address_postal_code] ?? ""} ${
                            form[businessFieldNames.address_city] ?? ""
                          } ${form[businessFieldNames.address_district] ?? ""}, Italy`

                          geocodeAddress(geocoder, fullAddress, (location) => {
                            setField(businessFieldNames.geo_location, location.toJSON(), setForm)
                            setAddressChanged(false)
                            setMarkerCalculated(true)
                          })
                        } else {
                          scrollToError()
                        }
                      }}
                    >
                      {markerCalculated ? "Aggiorna" : "Calcola posizione"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </GoogleMapsApiWrapper>
        </Col>
      </Row>
      {formStatus === FormState.editing && (
        <Row className="mt-3">
          <Col lg={8} className="mx-auto d-flex justify-content-center">
            <div className="d-inline-flex ">
              <Form.Check>
                <Form.Check.Input
                  checked={form[businessFieldNames.form_address_check] ?? false}
                  disabled={addressChanged || !markerCalculated}
                  onChange={(e) => setField(businessFieldNames.form_address_check, e.target.checked, setForm)}
                  isInvalid={!!errors[businessFieldNames.form_address_check]}
                />
                <Form.Check.Label>Confermo la posizione indicata sulla mappa</Form.Check.Label>
              </Form.Check>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}
