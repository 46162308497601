
import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import  { Navigate } from 'react-router-dom'

export default function BusinessCard() {
  React.useEffect(() => {
    window.location.replace('https://linktr.ee/diegomariotti')
  }, [])
}



