import { Status } from "@googlemaps/react-wrapper";
import AwaitNavigate from "common/AwaitNavigate";
import GoogleMapsApiWrapper, {
  geocodeAddress,
  Marker,
  MyMapComponent,
} from "common/GoogleMapsApi";
import SpinnerCentered from "common/SpinnerCentered";
import { useAuth } from "context/AuthContext";
import JoppysImage, { icBusinessServices, iMapPin } from "img/JoppysImage";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  ToggleButton,
} from "react-bootstrap";
import {
  createBusinessDraft,
  useBusinessDraftDocSingle,
} from "scripts/private";
import {
  buildErrors,
  businessInitialFields as fields,
  checkNoErrors,
  getInitalAddForm,
  BusinessTypes,
  busNames,
  busPetTypes,
} from "routes/business/app/BusinessForm";
import InfoCard from "common/InfoCard";
import Padding from "common/Padding";

function InputGroupBox({ id, as, value, onChange, errors, support, ...props }) {
  let formControl = (
    <>
      <Form.Control
        type={fields[id].type ?? "text"}
        value={value}
        onChange={onChange}
        isInvalid={!!errors[id]}
        aria-describedby={`${id}_prepend`}
      />
      {errors[id] !== " " && (
        <Form.Control.Feedback type="invalid">
          {errors[id]}
        </Form.Control.Feedback>
      )}
    </>
  );

  if (support) {
    formControl = (
      <>
        <InputGroup>
          <InputGroup.Text id={`${id}_prepend`}>{support}</InputGroup.Text>
          {formControl}
        </InputGroup>
      </>
    );
  }
  return (
    <Form.Group key={id} as={as} controlId={id} {...props}>
      <Form.Label>{fields[id].label}</Form.Label>
      {formControl}
    </Form.Group>
  );
}

function InputSwitchCheck({ id, as, checked, onChange, ...props }) {
  return (
    <Form.Check
      as={as}
      checked={checked}
      type={fields[id].type}
      id={id}
      label={fields[id].label}
      onChange={onChange}
      {...props}
    />
  );
}

function buildErrorsAddBusiness(form, fields) {
  const newErrors = buildErrors(form, fields);
  if (!form["type"]) newErrors["type"] = fields["type"].error;
  if (!form["form.check"]) newErrors["form.check"] = " ";
  return newErrors;
}

export default function AddBusiness() {
  const { currentUser } = useAuth();
  const draftDoc = useBusinessDraftDocSingle(currentUser.uid);
  const [status, setStatus] = useState("");
  const [submitCount, setSubmitCount] = useState(0);
  const [addressChanged, setAddressChanged] = useState(false);
  const [markerCalculated, setMarkerCalculated] = useState(false);
  const [form, setForm] = useState(getInitalAddForm());
  const [geocoder, setGeocoder] = useState();
  const [step, setStep] = useState(0);
  // const hasSubmitCountChanged = useCompare(submitCount)

  let errors = {};

  console.log("step: " + step);
  console.log(form);

  let resetForm = () => {
    setStep(0);
    setForm(getInitalAddForm());
    setMarkerCalculated(false);
    document.getElementById("add-business-form").reset();
  };

  if (submitCount) {
    errors = buildErrorsAddBusiness(form, fields);
    console.log("error");
    console.log(errors);
  } else {
    errors = { submitted: false };
  }

  function setField(field, value) {
    console.log(field + " " + value);

    if (field === "type" && form["type"] !== value) {
      // if type changes we need to reset
      resetForm();
    }

    // conditionalFields are fields also controlled by code
    let conditionalFields = {};
    if (fields[field]?.isAddressField) {
      setAddressChanged(true);
      conditionalFields = { "form.check": false };
    }

    if (field === "more.home_service") {
      // if home service is deactivated the range value must reset
      if (!value) {
        conditionalFields = { "more.home_service_range": 0 };
      }
    }

    setForm((currentForm) => {
      return {
        ...currentForm,
        [field]: value,
        ...conditionalFields,
      };
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 0) {
      if (!form["type"]) {
        setSubmitCount((currentSubmitCount) => currentSubmitCount + 1);
        return false;
      } else {
        setStep((step) => step + 1);
        setSubmitCount(0);
        return true;
      }
    } else {
      if (!checkNoErrors(buildErrorsAddBusiness(form, fields))) {
        setSubmitCount((currentSubmitCount) => currentSubmitCount + 1);
        return false;
      } else {
        try {
          await createBusinessDraft(form, currentUser.uid);
          setStatus("ok");
        } catch (e) {
          setStatus(e.code || "?");
        }
      }
    }
  };

  useEffect(() => {
    if (submitCount) {
      const invalidFields = document.getElementsByClassName("is-invalid");
      if (invalidFields.length > 0) {
        invalidFields[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [submitCount]);

  let content;

  if (!draftDoc) {
    // loading
    content = <SpinnerCentered />;
  } else if (draftDoc.data) {
    // refused
    content = (
      <div>
        {"Hai già aggiunto la tua attività. Verrai reindirizzato alla "}
        <AwaitNavigate to="/business/app" seconds={2}>
          dashboard
        </AwaitNavigate>
        {"."}
        <br />
      </div>
    );
  } else {
    // ok

    if (status === "") {
      let stepForm = [
        // selezione categoria
        {
          header: "Seleziona la categoria della tua attività",
          body: (
            <>
              <Form.Group as={Row} className="justify-content-center gx-4 gy-3">
                {[
                  BusinessTypes.VET,
                  BusinessTypes.GROOMER,
                  BusinessTypes.BREEDER,
                  BusinessTypes.TRAINER,
                  BusinessTypes.OTHER,
                ].map((busId) => (
                  <Col xs="auto" key={busId}>
                    <ToggleButton
                      variant={form["type"] === busId ? "primary" : "light"}
                      id={busId}
                      type="radio"
                      name="xradio"
                      value={busId}
                      className={`${!!errors["type"] && "is-invalid"}`}
                      onChange={(e) =>
                        setField("type", parseInt(e.currentTarget.value))
                      }
                    >
                      <JoppysImage
                        className="mb-2"
                        style={{ width: "90px" }}
                        content={icBusinessServices[busId]}
                      />
                      <br />
                      {busNames[busId]}
                    </ToggleButton>
                  </Col>
                ))}

                <Form.Control.Feedback className="text-center" type="invalid">
                  {errors["type"]}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Row className="align-items-center text-center">
                <Col lg={8} className="mx-auto">
                  <InfoCard>
                    Appartieni ad un'altra categoria del mondo degli animali?
                    <br />
                    <Link to="/contacts">Lasciaci la tua email</Link> e ti contatteremo appena ci saranno novità
                  </InfoCard>
                </Col>
              </Row> */}
            </>
          ),
        },
        // address
        {
          header: "Indirizzo",
          body: (
            <>
              <Col lg={8} className="mx-auto text-center">
                <div className="mb-3">
                  I dati inseriti saranno riportati sulla scheda del tuo profilo
                  e appariranno come informazioni base nelle ricerche.
                </div>
                {/* <InfoCard>I dati di questa sezione saranno pubblici sulla tua pagina</InfoCard> */}
              </Col>
              <div className="vstack gap-2 col-md-8 mx-auto">
                <InputGroupBox
                  id="title"
                  errors={errors}
                  onChange={(e) => setField(e.target.id, e.target.value)}
                />
                <Row>
                  <InputGroupBox
                    id="address.street"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={8}
                  />
                  <InputGroupBox
                    id="address.house_number"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={4}
                  />
                </Row>
                <Row>
                  <InputGroupBox
                    id="address.city"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                </Row>
                <Row>
                  <InputGroupBox
                    id="address.district"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={7}
                  />
                  <InputGroupBox
                    id="address.postal_code"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    as={Col}
                    xs={5}
                  />
                </Row>
              </div>
            </>
          ),
        },
        // map
        {
          header: "Posizione sulla mappa",
          body: (
            <>
              <Row>
                <Col lg={8} className="mx-auto text-center">
                  Per farti trovare è importante che la posizione della tua
                  attività sia corretta.
                </Col>
              </Row>

              <Row>
                <Col lg={8} className="mx-auto">
                  <GoogleMapsApiWrapper
                    callback={(status, loader) => {
                      if (status === Status.SUCCESS) {
                        loader.load().then((google) => {
                          !geocoder && setGeocoder(new google.maps.Geocoder());
                        });
                      }
                    }}
                  >
                    <div className="position-relative">
                      <MyMapComponent
                        className="rounded-3"
                        height={400}
                        zoom={17}
                        center={form["address.geo.location"]}
                      >
                        {form["address.geo.location"] && (
                          <Marker
                            draggable={true}
                            position={form["address.geo.location"]}
                            onDragEnd={(e) => {
                              setField(
                                "address.geo.location",
                                e.latLng.toJSON()
                              );
                            }}
                          />
                        )}
                      </MyMapComponent>

                      {(addressChanged || !markerCalculated) && (
                        <div>
                          <div className="position-absolute w-100 h-100 top-50 start-50 translate-middle bg-dark opacity-75"></div>
                          <div className="position-absolute top-50 start-50 translate-middle bg-light rounded-4 p-4 text-center">
                            <div className="mb-3">
                              {" "}
                              {markerCalculated ? (
                                <>
                                  L'indirizzo è stato modificato. <br />
                                  Ricalcola la posizione del cursore.
                                </>
                              ) : (
                                <>
                                  <InfoCard>
                                    Premi “calcola posizione”, il cursore{" "}
                                    <JoppysImage
                                      style={{ height: "24px" }}
                                      content={iMapPin}
                                      className="mx-2"
                                    />{" "}
                                    si posizionerà sull’indirizzo da te
                                    indicato.
                                  </InfoCard>
                                </>
                              )}
                            </div>
                            <Button
                              onClick={(e) => {
                                const fullAddress = `${
                                  form["address.street"] ?? ""
                                }, ${form["address.house_number"] ?? ""}, ${
                                  form["address.postal_code"] ?? ""
                                } ${form["address.city"] ?? ""} ${
                                  form["address.district"] ?? ""
                                }, Italy`;

                                geocodeAddress(
                                  geocoder,
                                  fullAddress,
                                  (location) => {
                                    setField(
                                      "address.geo.location",
                                      location.toJSON()
                                    );
                                    // setField("form.check", false)
                                    setAddressChanged(false);
                                    setMarkerCalculated(true);
                                  }
                                );
                              }}
                            >
                              {markerCalculated
                                ? "Aggiorna"
                                : "Calcola posizione"}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </GoogleMapsApiWrapper>
                </Col>
              </Row>
              <Row>
                <Col lg={8} className="mx-auto">
                  <InfoCard>
                    Sposta il cursore per correggere la posizione se necessario
                    e poi premi “conferma posizione”
                  </InfoCard>
                </Col>
              </Row>
              <Row>
                <Col lg={8} className="mx-auto d-flex justify-content-center">
                  <div className="d-inline-flex ">
                    <Form.Check>
                      <Form.Check.Input
                        id="form.check"
                        checked={form["form.check"] ?? false}
                        disabled={addressChanged || !markerCalculated}
                        onChange={(e) =>
                          setField("form.check", e.target.checked)
                        }
                        isInvalid={!!errors["form.check"]}
                      />
                      <Form.Check.Label htmlFor="form.check">
                        Confermo la posizione indicata sulla mappa
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                </Col>
              </Row>
            </>
          ),
        },
        // reperibilità
        {
          header: "Reperibilità",
          busTypes: [BusinessTypes.VET],
          body: (
            <>
              <Row>
                <Col sm={"auto"} className="mx-auto">
                  <InputSwitchCheck
                    id="more.appointment_only"
                    onChange={(e) => setField(e.target.id, e.target.checked)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="text-center">
                    L’attività svolge servizio di emergenza?
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Form.Group>
                    {[1, 2, 0].map((id) => (
                      <Form.Check
                        key={id}
                        type="radio"
                        name="more.reach"
                        id={`reach_${id}`}
                        label={fields["more.reach"].label[id]}
                        value={id}
                        onChange={(e) => {
                          setField(
                            e.currentTarget.name,
                            parseInt(e.currentTarget.value)
                          );
                        }}
                        checked={form["more.reach"] === id}
                        isInvalid={!!errors["more.reach"]}
                        className={!!errors["more.reach"] && "is-invalid"}
                      />
                    ))}
                    <Form.Control.Feedback
                      className="text-center"
                      type="invalid"
                    >
                      {errors["more.reach"]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ),
        },
        // contacts
        {
          header: "Contatti",
          body: (
            <>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="text-center">
                    Inserisci almeno un numero di telefono per farti sempre
                    rintracciare da chi ricerca la tua attiità.
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={"auto"}>
                  <InputGroupBox
                    id="phone.primary.num"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    support={<i className="bi bi-telephone"></i>}
                  />
                </Col>
                {form["more.reach"] !== 0 && (
                  <Col xs={"auto"}>
                    <div className="form-label">&nbsp;</div>
                    <InputSwitchCheck
                      id="phone.primary.h24"
                      onChange={(e) => setField(e.target.id, e.target.checked)}
                      className="my-3"
                    />
                  </Col>
                )}
                <Col xs={"auto"}>
                  <InputGroupBox
                    id="phone.primary.note"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={"auto"}>
                  <InputGroupBox
                    id="phone.secondary.num"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    support={<i className="bi bi-telephone"></i>}
                  />
                </Col>
                {form["more.reach"] !== 0 && (
                  <Col xs={"auto"}>
                    <div>
                      <div className="form-label">&nbsp;</div>
                      <InputSwitchCheck
                        className="my-3"
                        id="phone.secondary.h24"
                        onChange={(e) =>
                          setField(e.target.id, e.target.checked)
                        }
                      />
                    </div>
                  </Col>
                )}
                <Col xs={"auto"}>
                  <InputGroupBox
                    id="phone.secondary.note"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xs={"auto"}>
                  <InputGroupBox
                    id="email"
                    errors={errors}
                    onChange={(e) => setField(e.target.id, e.target.value)}
                    support="@"
                  />
                </Col>
              </Row>
            </>
          ),
        },
        {
          header: "Ulteriori informazioni",
          busTypes: [
            BusinessTypes.VET,
            BusinessTypes.GROOMER,
            BusinessTypes.TRAINER,
          ],
          body: (
            <>
              <Row className="justify-content-center align-items-end">
                <Col xs="auto">
                  <InputSwitchCheck
                    id="more.home_service"
                    onChange={(e) => setField(e.target.id, e.target.checked)}
                    className="mx-3"
                  />
                </Col>
                <Col xs="auto">
                  <Form.Select
                    disabled={!form["more.home_service"]}
                    id="more.home_service_range"
                    value={form["more.home_service_range"]}
                    aria-label="Raggio"
                    onChange={(e) =>
                      setField(e.target.id, parseInt(e.target.value))
                    }
                  >
                    <option value={0}>
                      {fields["more.home_service_range"].label}
                    </option>
                    {[5, 10, 20, 30, 40, 50].map((e) => (
                      <option key={`home_service_range_${e}`} value={e}>
                        {e} km
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              {[BusinessTypes.VET, BusinessTypes.GROOMER].includes(
                form["type"]
              ) && (
                <>
                  <Row>
                    <Col
                      lg={8}
                      className="mx-auto mt-3 text-center text-larger"
                    >
                      Animali trattati
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col xs={"auto"}>
                      {busPetTypes[form["type"]] &&
                        busPetTypes[form["type"]].map((e) => (
                          <InputSwitchCheck
                            key={`pet_type_${e}`}
                            id={`more.pet_type.${e}`}
                            onChange={(e) =>
                              setField(e.target.id, e.target.checked)
                            }
                          />
                        ))}
                    </Col>
                  </Row>
                </>
              )}
              {fields["more.self_service"].busTypes.includes(form["type"]) && (
                <>
                  <Row>
                    <Col
                      lg={8}
                      className="mx-auto mt-3 text-center text-larger"
                    >
                      Servizio self-service
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <Form.Group>
                        {[1, 2, 0].map((id) => (
                          <Form.Check
                            key={`self_service_${id}`}
                            type="radio"
                            name="more.self_service"
                            id={`self_service_${id}`}
                            label={fields["more.self_service"].label[id]}
                            value={id}
                            onChange={(e) => {
                              setField(
                                e.currentTarget.name,
                                parseInt(e.currentTarget.value)
                              );
                            }}
                            checked={form["more.self_service"] === id}
                            isInvalid={!!errors["more.self_service"]}
                            className={
                              !!errors["more.self_service"] && "is-invalid"
                            }
                          />
                        ))}
                        <Form.Control.Feedback
                          className="text-center"
                          type="invalid"
                        >
                          {errors["more.self_service"]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ),
        },
        // owner info
        {
          header: "Titolare / Proprietario",
          body: (
            <>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="text-center mb-3">
                    Al fine di offrire ai proprietari di animali informazioni
                    certificate, è necessario inserire i dati del titolare /
                    proprietario del servizio professionale registrato.
                  </div>
                  <InfoCard>
                    Questi dati non saranno visibili agli utenti, ma saranno
                    necessari allo staff di Joppys per verificare le
                    informazioni.
                  </InfoCard>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} lg={4} className="mx-auto">
                  <div className="vstack gap-2">
                    <InputGroupBox
                      id="owner.name"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                    />
                    <InputGroupBox
                      id="owner.surname"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                    />
                    <InputGroupBox
                      id="owner.phone"
                      errors={errors}
                      onChange={(e) => setField(e.target.id, e.target.value)}
                      support={<i className="bi bi-telephone"></i>}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ),
        },
      ];
      let contentSteps = stepForm
        .slice(0, step === 0 ? 1 : stepForm.length)
        .map((e, index) => {
          if (!e.busTypes || e.busTypes.includes(form["type"])) {
            return (
              <Card key={index}>
                <Card.Body>
                  <div className="text-center text-huge mb-3">{e.header}</div>
                  <div className="vstack gap-3">{e.body}</div>
                </Card.Body>
              </Card>
            );
          } else {
            return <></>;
          }
        })
        .concat();
      content = (
        <>
          <Form
            id="add-business-form"
            noValidate
            onSubmit={handleSubmit}
            className="needs-validation"
            key="form"
          >
            <div className="vstack gap-3 gap-lg-4">
              <Row>
                <Col lg={8} className="mx-auto text-center">
                  <div className="text-huge mb-2">
                    Creazione scheda professionale <b>gratuita</b>
                  </div>
                  <div>
                    In <u>pochi passi</u> potrai creare il tuo profilo digitale,
                    inserire i tuoi contatti e le informazioni base.
                    <br />
                    Gli utenti potranno così individuarti facilmente attraverso
                    la nostra piattaforma.
                  </div>
                </Col>
              </Row>
              {contentSteps}
              {step !== 0 && (
                <>
                  <Row>
                    <Col lg={8} className="mx-auto text-center">
                      <b>Ci siamo quasi!</b>
                      <br />
                      Il nostro staff prenderà in carico la tua richiesta nelle
                      prossime 48 ore.
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={4} className="mx-auto">
                      <Button type="submit" className="w-100 btn-block">
                        Salva
                      </Button>
                      {!!submitCount && Object.keys(errors).length !== 0 && (
                        <>
                          <div className="mt-2 invalid-feedback d-block text-center">
                            Alcuni campi riultano incompleti
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {step === 0 && (
                <>
                  <Row>
                    <Col xs={6} lg={4} className="mx-auto">
                      <Button type="submit" className="w-100 btn-block">
                        Prosegui
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Form>
        </>
      );
    } else if (status === "ok") {
      content = renderResult(status);

      function renderResult(status) {
        let title = "";

        let content;
        switch (status) {
          case "ok":
            title = "Operazione riuscita!";
            content = <>Complimenti, hai inserito la tua attività!</>;
            break;
          // case "permission-denied":
          //   title = "Errore!"
          //   content = (
          //     <>
          //       Si è verificato un problema
          //     </>
          //   )
          //   break
          default:
            title = "Errore!";
            content = status;
            break;
        }
        return (
          <Card className="rounded-4 p-5">
            <h3>{title}</h3>
            <div className="my-3">{content}</div>
            <div>
              {"Verrai reindirizzato alla "}
              <AwaitNavigate to="/business/app" seconds={2}>
                dashboard
              </AwaitNavigate>
            </div>
          </Card>
        );
      }
    }
  }

  return (
    <Container fluid className="bg-light py-5 min-height-100vh" >
      <Container>{content}</Container>
    </Container>
  );
}
