import AwaitNavigate from "common/AwaitNavigate";
import SpinnerCentered from "common/SpinnerCentered";
import { useAuth } from "context/AuthContext";
import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { useBusinessDraftDoc } from "scripts/private";
import { FormState } from "routes/business/app/BusinessForm";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

export default function EditBusiness() {
  console.log("render EditBusiness");
  const { currentUser } = useAuth();
  const draftDoc = useBusinessDraftDoc(currentUser.uid);
  const [formStatus, setFormStatus] = useState(FormState.idle);
  const [section, setSection] = useState();
  const [nextSection, setNextSection] = useState();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const onChangeSection = (e) => {
    e.preventDefault();
    if (section === e.target.id) {
      e.preventDefault();
      return;
    }
    if (formStatus === FormState.editing) {
      e.preventDefault();
      setNextSection(e.target.id);
      handleModalShow();
    } else if (formStatus === FormState.idle) {
      navigate(e.target.id);
    }
  };

  const handleModalNext = () => {
    setShow(false);
    navigate(nextSection);
    setFormStatus(FormState.idle);
  };

  const handleModalClose = () => setShow(false);
  const handleModalShow = () => setShow(true);

  let content;
  if (!draftDoc) {
    // loading
    content = <SpinnerCentered />;
  } else if (!draftDoc.data) {
    // refused
    // no business
    content = (
      <div>
        Non hai ancora aggiunto la tua attività!
        <br />
        Verrai reindirizzato alla{" "}
        <AwaitNavigate to="/business/app" seconds={2}>
          dashboard
        </AwaitNavigate>
        .
        <br />
      </div>
    );
  } else {
    // ok

    content = (
      <>
        <Tab.Container>
          <Row>
            <Col>
              <Nav id="app-sidebar" variant="pills">
                <NavLink
                  className="nav-link"
                  id="info"
                  to={"info"}
                  onClick={onChangeSection}
                >
                  Info generali
                </NavLink>
                <NavLink
                  id="address"
                  to={"address"}
                  className="nav-link"
                  onClick={onChangeSection}
                >
                  Indirizzo e mappa
                </NavLink>
                <NavLink
                  id="contacts"
                  to={"contacts"}
                  className="nav-link"
                  onClick={onChangeSection}
                >
                  Contatti
                </NavLink>
                <NavLink
                  id="owner_info"
                  to={"owner_info"}
                  className="nav-link"
                  onClick={onChangeSection}
                >
                  Info Proprietario
                </NavLink>
                <NavLink
                  id="pro"
                  to={"pro"}
                  className="nav-link"
                  onClick={onChangeSection}
                >
                  Pro
                </NavLink>
              </Nav>
            </Col>
          </Row>
          <Row className="p-4">
            <Col>
              <Outlet
                context={[formStatus, setFormStatus, draftDoc, setSection]}
              />
              {/* <Tab.Content>
                  <Tab.Pane eventKey="info">
                    <EditBusInfo
                      data={draftDoc.data}
                      state={formStatus}
                      onStatusChanged={(newStatus) => setFormStatus(newStatus)}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="address">ciao2</Tab.Pane>
                </Tab.Content>
           */}
            </Col>
          </Row>
        </Tab.Container>
        <Modal show={show} onHide={handleModalClose} centered>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Attenzione!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Non hai salvato le modifiche. Vuoi procedere senza salvare?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Torna alle modifiche
            </Button>
            <Button variant="primary" onClick={handleModalNext}>
              Ignora le modifiche
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      {content}
    </>
  );
}
