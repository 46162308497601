import { Form } from "react-bootstrap"
import { validateLength } from "scripts/Validation"

export const BusinessTypes = Object.freeze({
  VET:   1,
  GROOMER:  2,
  BREEDER: 3,
  TRAINER: 4,
  OTHER: 99,
});

// if busTypes is defined it's used as a filter
// if undefined it means all business use that field
export const businessInitialFields = {
  "type": { init: undefined, error: "È necessario selezionare la categoria della tua attività" },
  "title": { init: "", label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
  "address.street": { init: "", label: "Via", isAddressField: true, validate: true, error: " " },
  "address.house_number": { init: "", label: "Num. civico", isAddressField: true, validate: true, error: " " },
  "address.city": { init: "", label: "Comune", isAddressField: true, validate: true, error: " " },
  "address.district": { init: "", label: "Provincia", isAddressField: true, validate: true, error: " " },
  "address.postal_code": {
    init: "",
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
  "phone.primary.num": {
    init: "",
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.primary.note": {
    init: "",
    label: "Nota",
  },
  "phone.primary.main": {
    init: true,
  },
  "phone.primary.h24": {
    init: false,
    type: "switch",
    label: "Reperibilità H24",
  },
  "phone.secondary.num": { init: "", label: "Numero di telefono alternativo", type: "tel" },
  "phone.secondary.h24": {
    init: false,
    type: "switch",
    label: "Reperibilità H24",
  },
  "phone.secondary.note": {
    init: "", 
    label: "Nota",
  },
  "email": { init: "",  label: "Indirizzo e-mail", validate: true, error: "Campo obbligatorio" },
  "owner.name": { init: "",  label: "Nome", validate: true, error: "Campo obbligatorio" },
  "owner.surname": { init: "", label: "Cognome", validate: true, error: "Campo obbligatorio" },
  "owner.phone": { init: "", label: "Numero di telefono", type: "tel", validate: true, error: "Campo obbligatorio" },
  "more.reach": { busTypes: [BusinessTypes.VET ], validate: true, init: null, label: {0: "No", 1: "Sì, su chiamata", 2: "Sì, presenza in loco"}, type: "radio", error: "Campo obbligatorio"},
  "more.home_service": { init: false, label: "Servizio a domicilio", type: "switch"},
  "more.home_service_range": { init: 0, label: "Raggio (km)", type: "select"},
  "more.appointment_only": { busTypes: [ BusinessTypes.VET ], init: false, label: "Visita solo su appuntamento", type: "switch"},
  "more.pet_type.small": { init: false, label: "Piccoli animali", type: "switch"},
  "more.pet_type.dog": { init: false, label: "Cani", type: "switch"},
  "more.pet_type.cat": { init: false, label: "Gatti", type: "switch"},
  "more.pet_type.exotic": { init: false, label: "Animali esotici", type: "switch"},
  "more.pet_type.rabbit": { init: false, label: "Conigli", type: "switch"},
  "more.pet_type.horse": { init: false, label: "Cavalli", type: "switch"},
  "more.pet_type.farm": { init: false, label: "Animali da reddito", type: "switch"},
  "more.self_service": { busTypes: [ BusinessTypes.GROOMER ], validate: true, error: "Campo obbligatorio", init: null, label: {0: "Nessun servizio self-service", 1: "Servizio self-service (in orario di apertura)", 2: "Servizio self-service H24"}, type: "radio"},
}

export const busPetTypes = {
  [BusinessTypes.VET]: ["small", "horse", "exotic", "farm"],
  [BusinessTypes.GROOMER]: ["dog", "cat", "rabbit"],
  [BusinessTypes.BREEDER]: [],
  [BusinessTypes.TRAINER]: [],
  [BusinessTypes.OTHER]: []
}

export const busNames = {
  [BusinessTypes.VET]: "Veterinario",
  [BusinessTypes.GROOMER]: "Toelettatore",
  [BusinessTypes.BREEDER]: "Allevatore",
  [BusinessTypes.TRAINER]: "Educatore",
  [BusinessTypes.OTHER]: "Altro"
}

// 
export function getInitalAddForm () {
  let form = {}
  Object.keys(businessInitialFields).forEach((key) => {
    form[key] = businessInitialFields[key].init
  })
  return form
}

export function buildErrors(obj, fields) {
  let selectedType = obj["type"]
  let locErrors = {}
  Object.keys(fields).forEach((key) => {
    if (fields[key].validate) {
      
      if (!selectedType || (!!selectedType && (!fields[key].busTypes || fields[key].busTypes.includes(selectedType)))) {
        console.log("prova" + selectedType)
        let error = buildError(obj[key], fields[key])
        if (error) locErrors[key] = error
      }
    }
  })
  return locErrors
}

function buildError(val, field) {
  let error
  switch (field.type) {
    case "radio":
      if (val === null) error = field.error
      break
    case "bool":
      if (!val) error = field.error
      break
    default:
      if (!validateLength(val, field.minLength, true)) error = field.error
      break
  }
  return error
}

export function InputGroupBox({ id, as, value, onChange, errors, fields, innerRef, ...props }) {
  return (
    <Form.Group key={id} as={as} controlId={id}>
      <Form.Label>{fields[id].label}</Form.Label>
      <Form.Control
        {...props}
        ref={innerRef}
        type={fields[id].type ?? "text"}
        value={value || ""}
        onChange={onChange}
        isInvalid={!!errors[id]}
      />
      {errors[id] !== " " && <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>}
    </Form.Group>
  )
}

export const businessInfoFieldsNew = {
  type: { id: "type", error: "È necessario selezionare la categoria della tua attività" },
  title: { id: "title", label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
  address_street: { id: "address.street", label: "Via", isAddressField: true, validate: true, error: " " },
  address_houseNumber: {
    id: "address.house_number",
    label: "Num. civico",
    isAddressField: true,
    validate: true,
    error: " ",
  },
  address_city: { id: "address.city", label: "Comune", isAddressField: true, validate: true, error: " " },
  address_district: { id: "address.district", label: "Provincia", isAddressField: true, validate: true, error: " " },
  address_postalCode: {
    id: "address.postal_code",
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
  "phone.primary": {
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
  "owner.name": { label: "Nome", validate: true, error: "Campo obbligatorio" },
  "owner.surname": { label: "Cognome", validate: true, error: "Campo obbligatorio" },
  "owner.phone": { label: "Numero di telefono", type: "tel", validate: true, error: "Campo obbligatorio" },
}

export const businessFieldNames = {
  type: "type",
  title: "title",
  phone_primary: "phone.primary",
  owner_name: "owner.name",
  owner_surname: "owner.surname",
  owner_phone: "owner.phone",
  address_street: "address.street",
  address_house_number: "address.house_number",
  address_city: "address.city",
  address_district: "address.district",
  address_postal_code: "address.postal_code",
  geo_location: "address.geo.location",
  geo_lat: "address.geo.location.lat",
  geo_lng: "address.geo.location.lng",
  form_address_check: "NO_DB.address.check",
}

export const businessInfoFields = {
  [businessFieldNames.type]: { error: "È necessario selezionare la categoria della tua attività" },
  [businessFieldNames.title]: { label: "Nome attività", validate: true, minLength: 5, error: "Minimo 5 caratteri" },
}

export const businessContactsFields = {
  [businessFieldNames.phone_primary]: {
    label: "Numero di telefono principale",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
  "phone.secondary": { label: "Numero di telefono altenrativo", type: "tel" },
}
export const businessOwnerInfoFields = {
  [businessFieldNames.owner_name]: { label: "Nome", validate: true, error: "Campo obbligatorio" },
  [businessFieldNames.owner_surname]: { label: "Cognome", validate: true, error: "Campo obbligatorio" },
  [businessFieldNames.owner_phone]: {
    label: "Numero di telefono",
    type: "tel",
    validate: true,
    error: "Campo obbligatorio",
  },
}

export const businessAddressFields = {
  [businessFieldNames.address_street]: {
    label: "Via",
    isAddressField: true,
    validate: true,
    error: "Campo obbligatorio",
  },
  [businessFieldNames.address_house_number]: {
    label: "Num. civico",
    isAddressField: true,
    validate: true,
    error: "Campo obbligatorio",
  },
  [businessFieldNames.address_city]: {
    label: "Comune",
    isAddressField: true,
    validate: true,
    error: "Campo obbligatorio",
  },
  [businessFieldNames.address_district]: {
    label: "Provincia",
    isAddressField: true,
    validate: true,
    error: "Campo obbligatorio",
  },
  [businessFieldNames.address_postal_code]: {
    label: "CAP",
    type: "number",
    isAddressField: true,
    validate: true,
    minLength: 5,
    error: "CAP incompleto",
  },
  [businessFieldNames.form_address_check]: {
    validate: true,
    error: " ",
    type: "bool",
  },
}

export const FormState = {
  idle: 0,
  editing: 1,
  saving: 2,
}

export function scrollToError() {
  const invalidFields = document.getElementsByClassName("is-invalid")
  if (invalidFields.length > 0) {
    invalidFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
  }
}

export function getSubSet(errors, tag) {
  const separator = "."
  const asArray = Object.entries(errors)
  // filter only tag
  const filtered = asArray.filter(([key, value]) => key.startsWith(tag + separator))
  errors = Object.fromEntries(filtered)
  return errors
}

export function checkNoErrors(errors) {
  if (Object.keys(errors).length === 0) {
    return true
  } else {
    return false
  }
}

export function setField(field, value, setForm) {
  setForm((currentForm) => {
    return {
      ...currentForm,
      [field]: value,
    }
  })
}
