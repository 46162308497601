import { getStripePayments, getProducts } from "@stripe/firestore-stripe-payments"
import { firebaseApp } from "scripts/FirebaseApp"

const payments = getStripePayments(firebaseApp, {
  productsCollection: "stripe_products",
  customersCollection: "stripe_customers",
})
// getProducts(payments, {
//   includePrices: true,
//   activeOnly: true,
// }).then((e) => console.log(e))

export const pollo = 2
