// import Maps from "common/Maps"

import GoogleMapsApiWrapper, { Marker, MyMapComponent } from "common/GoogleMapsApi"

import { Fragment } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useLoaderData } from "react-router-dom"

export default function BusinessPublic() {
  const doc = useLoaderData()
  const docData = doc?.data
  document.title = (docData?.title || "Pagina non trovata") + " | Joppys"

  return (
    <>
      <Container className="py-5">
        <Card className="p-4 p-md-5 rounded-4">
          {!doc && (
            // <div id="loader">
            //   <div className="spinner-border" role="status">
            //     <span className="visually-hidden">Loading...</span>
            //   </div>
            // </div>
            <>Non esiste</>
          )}
          {doc && (
            <>
              <Row>
                <Col md={5}>
                  <div className="d-grid gap-3">
                    <div>
                      <h3 className="mb-2 text-uppercase">{docData.title}</h3>
                      {/* <!-- <p>Apre domani alle 9:00</p> --> */}
                    </div>
                    <div>
                      <h5 className="mb-2">Indirizzo</h5>
                      <p>
                        {docData.address.street} {docData.address.house_number}
                        <br />
                        {docData.address.postal_code} {docData.address.city}, {docData.address.district}
                        <br />
                        <a
                          href={
                            "https://www.google.com/maps/?daddr=" +
                            docData.address.geo.location.lat +
                            "," +
                            docData.address.geo.location.lng
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ottieni indicazioni
                        </a>
                      </p>
                    </div>
                    <div>
                      <h5 className="mb-2">Contatti</h5>
                      <p>
                        <i className="bi bi-telephone me-2"></i>
                        {docData.phone.primary}
                        {docData.phone.secondaries &&
                          docData.phone.secondaries.map((element, index) => {
                            return (
                              <Fragment key={index}>
                                <br />
                                <i className="bi bi-telephone me-2"></i>
                                {element}
                              </Fragment>
                            )
                          })}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={7}>
                  <GoogleMapsApiWrapper>
                    <MyMapComponent className="rounded-3" height={300} center={docData.address.geo.location} zoom={17}>
                      <Marker position={docData.address.geo.location} draggable={false} />
                    </MyMapComponent>
                  </GoogleMapsApiWrapper>
                  {/* <div id="map" className="rounded" style={{ height: "300px" }}></div> */}
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Container>
    </>
  )
}
